import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import LiveTracking from "./Components/LiveTracking/LiveTracking";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={
              <div>
                <h1>Welcome to the Home Page</h1>
              </div>
            }
          />
          <Route path="/live-tracking/:mobile" element={<LiveTracking />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
