import React, { useEffect, useRef, useState } from "react";
import { io } from "socket.io-client";
import "./LiveTracking.css";
import { useParams, useLocation } from "react-router-dom";
// Helper function to calculate bearing between two points
const getBearing = (startLat, startLng, endLat, endLng) => {
  const startLatRad = (Math.PI * startLat) / 180;
  const startLngRad = (Math.PI * startLng) / 180;
  const endLatRad = (Math.PI * endLat) / 180;
  const endLngRad = (Math.PI * endLng) / 180;

  const dLng = endLngRad - startLngRad;

  const y = Math.sin(dLng) * Math.cos(endLatRad);
  const x =
    Math.cos(startLatRad) * Math.sin(endLatRad) -
    Math.sin(startLatRad) * Math.cos(endLatRad) * Math.cos(dLng);

  let bearing = (Math.atan2(y, x) * 180) / Math.PI;
  bearing = (bearing + 360) % 360;
  return bearing;
};

// http://localhost:3000/live-tracking/:mobile?startLat=17.5184123&startLng=78.3393763&destLat=17.4129166&destLng=78.4632669

const LiveTracking = () => {
  const mapRef = useRef(null); // Reference for the map container div
  const [map, setMap] = useState(null); // State to hold the Google Map instance
  const [sourceMarker, setSourceMarker] = useState(null); // State to hold the source marker

  const socket = useRef();

  // Use useParams to get mobile number from the URL
  const { mobile } = useParams();
  const location = useLocation();

  // Get start and destination coordinates from the query string
  const queryParams = new URLSearchParams(location.search);
  const startLat = parseFloat(queryParams.get("startLat"));
  const startLng = parseFloat(queryParams.get("startLng"));
  const destLat = parseFloat(queryParams.get("destLat"));
  const destLng = parseFloat(queryParams.get("destLng"));

  //   live tracking server
  // const phone = "9000000000";
  const phone = mobile;

  useEffect(() => {
    socket.current = io("https://womenrapido.nuhvin.com/");
    socket.current.emit("new-user-add", phone);
    socket.current.on("get-users", (users) => {
      console.log(users);
    });
    // Initialize the map once the component mounts
    const initializeMap = () => {
      // const initialSource = { lat: 17.5184123, lng: 78.3393763 s

      const initialSource = { lat: startLat, lng: startLng };
      const destination = { lat: destLat, lng: destLng };

      // Create a new Google Map centered at the source
      const mapInstance = new window.google.maps.Map(mapRef.current, {
        zoom: 10,
        center: initialSource,
        gestureHandling: "greedy",
      });

      // Change the cursor style on the map
      mapInstance.setOptions({
        styles: [
          {
            featureType: "all",
            elementType: "all",
            stylers: [
              {
                visibility: "on",
              },
            ],
          },
        ],
      });

      // Add a default pin for the destination
      new window.google.maps.Marker({
        position: destination,
        map: mapInstance,
      });

      // Initialize DirectionsService and DirectionsRenderer
      const directionsService = new window.google.maps.DirectionsService();
      const directionsRenderer = new window.google.maps.DirectionsRenderer({
        suppressMarkers: true, // Suppress default markers (pins) for source/destination
      });

      // Attach the renderer to the map
      directionsRenderer.setMap(mapInstance);

      // Request a route between source and destination
      directionsService.route(
        {
          origin: initialSource,
          destination: destination,
          travelMode: window.google.maps.TravelMode.DRIVING, // or 'WALKING', 'BICYCLING', etc.
        },
        (response, status) => {
          if (status === "OK") {
            directionsRenderer.setDirections(response);
          } else {
            console.error("Directions request failed due to " + status);
          }
        }
      );

      // Save the map instance to the state
      setMap(mapInstance);

      // Create and add a custom arrow marker for the source location
      const arrowMarker = new window.google.maps.Marker({
        position: initialSource,
        map: mapInstance,
        icon: {
          path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
          scale: 5,
          strokeColor: "#FF0000",
          strokeWeight: 2,
          rotation: 0, // Initial rotation
        },
      });

      // Save the source marker to the state
      setSourceMarker(arrowMarker);
    };

    // Check if the Google Maps script is loaded, if not load it
    if (!window.google) {
      const script = document.createElement("script");
      script.src =
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyAvJUZ3vsynRkQhXSdZL-BIFo26bXH-Al8&callback=initMap";
      script.async = true;
      script.defer = true;
      window.initMap = initializeMap; // Set initMap to be called when script loads
      document.head.appendChild(script);
    } else {
      initializeMap(); // Initialize map if script is already loaded
    }
  }, [mobile, startLat, startLng, destLat, destLng]);

  // Function to update the marker position and rotate it based on the direction
  const updateMarkerPosition = (lat, lng, nextLat, nextLng) => {
    if (sourceMarker && map) {
      const newPosition = new window.google.maps.LatLng(lat, lng);

      // Calculate the bearing/direction for rotation
      const bearing = getBearing(lat, lng, nextLat, nextLng);

      // Update marker's position and rotation (direction)
      sourceMarker.setPosition(newPosition);
      sourceMarker.setIcon({
        ...sourceMarker.getIcon(),
        rotation: bearing, // Rotate marker according to the calculated bearing
      });

      map.setCenter(newPosition); // Optional: recenter map to the new position
    }
  };

  //   useEffect(() => {
  //     socket.current.on("send-coordinates", (data) => {
  //         console.log(data)
  //       }
  //     )
  //     const path = [
  //       { lat: 17.5184123, lng: 78.3393763 },
  //       { lat: 17.5284123, lng: 78.3493763 },
  //       { lat: 17.5384123, lng: 78.3593763 },
  //       { lat: 17.5484123, lng: 78.3693763 },
  //       { lat: 17.5584123, lng: 78.3793763 },
  //     ]; // Predefined path coordinates

  //     let index = 0;

  //     // Update marker position every 2 seconds
  //     const interval = setInterval(() => {
  //       if (index < path.length - 1) {
  //         const current = path[index];
  //         const next = path[index + 1];
  //         updateMarkerPosition(current.lat, current.lng, next.lat, next.lng);
  //         index++;
  //       } else {
  //         index = 0; // Reset to loop the path again
  //       }
  //     }, 2000); // Update every 2 seconds

  //     return () => clearInterval(interval); // Cleanup on component unmount
  //   }, [sourceMarker, map]);

  useEffect(() => {
    // Listen for coordinates from the server
    console.log("first");
    socket.current.on("receive-coordinates", (data) => {
      console.log("inside sockets");
      console.log(data); // Log received coordinates for debugging purposes  // coordinates
      if (data.coordinates) {
        const { lng, lat } = data.coordinates; // Extract longitude and latitude from the array

        // Update the marker's position with new coordinates
        updateMarkerPosition(lat, lng, lat, lng); // Use same coordinates as the next for now
      }
    });
    console.log("secod");

    return () => {
      socket.current.off("send-coordinates");
    };
  }, [sourceMarker, map]);

  return (
    <div>
      <div
        id="map"
        ref={mapRef}
        style={{ height: "100vh", width: "100%" }}
      ></div>
    </div>
  );
};

export default LiveTracking;
